.App-header {
  background-color: #3F4552;
  color: white;
  display: flex;
  flex-direction: row;
  height: 100%;
}

body { background-color: #222; }

html, body {
  overscroll-behavior-x: none;
  cursor: default;
  overflow: hidden;
}

.clearfix {
  clear: both;
}

.newmap {
  text-align: center;
}

.newmap {
  padding: 7px 0 10px;
}
.newmap a {
  font-size: 11pt;
  color:  #aaa;
  text-decoration: none;
  margin: 0 0.7em;
  padding: 7px 0 10px 0;
}
.newmap a:hover {
  cursor: pointer;
  color: #fff;
}

.newmap .settings label {
  font-size: 11pt;
}

.loader {
  z-index: 1; /* compete with .rel */
}

.overlay {
  position: fixed;
  x: 0;
  y: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  display: block;
  z-index: 2;
}

.centered {
  position: absolute;
  text-align: center;
  top: 45%;
  left: 50%;
  margin: -50px 0 0 -150px;
  height: 100px;
  width: 300px;
  background-color: rgba(255,255,255,0.75);
  padding: 20px 20px 0 20px;
  border-radius: 1em;
  box-shadow: 1px 2px 5px 2px #000000;
}

.overlay .login {
  color: #bbb;
  background-color: green;
  padding: 5px 7px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16pt;
  box-shadow: 1px 2px 5px -1px rgba(0,0,0,1.0);
}
.overlay .login:hover {
  color: #fff;
}
.overlay p {
  color: #333;
}


.menu {
  text-align: left;
  font-size: 11pt;
  margin: 0;
  width: 100%;
  color: #bbb;
  background-color: #3F4552;
  padding: 3px 0;
}
ul.view {
  text-align: left;
  flex: 1 1 100px;
  padding: 0 0 0 3px;
  width: 190px;
}
.view li { padding: 0; }
.view img { opacity: 0.3; height: 28px; width: 28px; }
.view a.current img, .view a:hover img { opacity: 1.0; }
.view a {
/*  display: inline-block;*/
  font-size: 12pt;
  color: #777;
  width: 100%;
  border-radius: 3px;
  padding: 2px;
  margin: 1px 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.view a.current,
.view a:hover {
  background-color: #333;
  color: #eee;
}
.view a span {
  padding: 3px 0 3px 8px;
}
ul { list-style-type: none; margin: 0; padding: 0; }
.menu .settings { display: inline-block; border-left: 1px solid #777; }
.menu .settings div { display: inline-block; padding:  0 7px; }
.menu .settings label { padding: 0 1px; }

.menu li { display: inline-block; }
.menu.main li { padding:  0.6em 0.2em; }
.menu.main li a { padding: 0.8em; }

.menu .current, .newmap { background-color: #222; }
.menu label { margin: 0 0.5em; }
.menu a         { color: #bbb; }
.menu a.current { color: #eee; }
.menu.main a.current { color: #eee; font-weight: normal; }
.menu a:hover   { color: #fff; cursor: pointer; }

.search { padding: 10px; width: 100%; flex: 0; margin-bottom: 10px; }
.search input {
  width: 150px; margin-right: 4px; font-size: 10pt;
  background-color: #777;
  border-radius: 5px;
  border: 1px solid #222;
}
.search input:hover { background-color: #aaa; }
.search input:focus { background-color: #eee; }

#currentlyPlaying {
  background-color: black;
  flex: 0 1;
  color: #bbb;
  padding: 3px 15px;
}

#currentlyPlaying h3 {
  text-align: left;
  color: #bbb !important;
  font-size: 1.1em;
  margin: 10px 0 5px 0;
}

#currentlyPlaying p {
  font-size: 10pt;
  margin: 0 0 10px 0;
}

#currentlyPlaying img {
  width: 100%;
}

#currentlyPlaying img.button {
  cursor: pointer;
  padding: 10px 0;
  width: 24px;
  float: right;
  opacity: 0.5;
}

#currentlyPlaying img.button:hover {
  opacity: 1.0;
}

#currentlyPlaying .progressBg {
  background-color: #333;
}
#currentlyPlaying .progress {
  margin: 5px 0 15px 0;
  height: 3px;
  background-color: #999;
  border-radius: 2px;
}

.left {
  float: left;
}
.right {
  float: right;
}
.loader .icons {
  flex-direction: row;
}
.savedTracks .right {
  margin-top: 40px;
}
.loader .icon {
  opacity: 0.3; height: 24px; width: 24px;
}
.loader .right .icon {
  height: 16px; width: 16px;
  margin: 0 0 0 3px;
}
.loader .icon.reload {
  height: 16px; width: 16px;
}

.loader .icon:hover {
  opacity: 0.8;
}
.loader .grid .icon.grid,
.loader .rows .icon.rows,
.loader .showOnlySelected .icon.showOnlySelected {
  opacity: 1.0;
}

.row {
  display: flex;
  flex-direction: row;
}
.tabs {
  margin: 10px 0 0;
}
.tab {
  text-decoration: none;
  color: white;
  display: block;
}
.tab {
  opacity: 0.5;
  cursor: pointer;
  padding: 5px 5px;
}
.tab:hover,
.tab.selected {
  opacity: 1.0;
}

.loader {
  height: 100vh;
  display: block;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  background-color: #1b1b1b;
  padding: 0 10px;
}
.loader.narrow {
  width: 240px;
}
.loader.wide {
  width: 340px;
}
.loader h3 {
  display: block;
  color: #bbb;
  padding: 0;
  margin: 18px 0 7px 0px;
  font-size: 1.3em;
}
.loader h3 span {
  display: block;
  width: 100%;
  font-size: 10pt;
  color: #777;
  margin: 2px 0 7px 0;
  font-weight: normal;
/*  font-style: italic;*/
}
.loader > div {
  display: flex;
  flex-direction: column;
  font-size: 10pt;
}
.albums {
  overflow-y: hidden;
}
.loader .savedTracks {
  flex: 1 1 30%;
  overflow: hidden;
  min-height: 200px;
}
.loader .albums {
  flex: 1 1 70%;
  overflow: hidden;
  min-height: 200px;
}
.loader .bundles, .loader .playlists {
  overflow: hidden;
}
.loader .bundles .intro {
  background-color: #5553;
  font-size: 10pt;
  color: #bbb;
  padding: 0.5em 2em 0.5em 2em;
  line-height: 1.5;
  margin-bottom: 5px;
}
.loader .bundle h3 {
  font-size: 1.7em;
}
.loader .bundle h3 span {
  margin-top: 7px;
}
.loader .bundle a {
  display: block;
}
.loader ul {
  flex: 1 1;
}
.scroller img {
  opacity: 0.3;
  cursor: pointer;
}
.scroller:hover img {
/*  opacity: 0.7;*/
}
.scroller:hover img:hover {
  opacity: 1.0;
}
.grid.showOnlySelected li,
.rows.showOnlySelected li,
.grid.showOnlySelected ul {
  display: none;
}
.showOnlySelected li.selected,
/*.showOnlySelected li.tab,*/
.grid.showOnlySelected .hasSelected {
  display: block;
}
.scroller .selected {
  opacity: 1.0;
}
.albums.scroller img {
  display: flex;
  cursor: pointer;
  height: 48px;
}
.scroller {
  display: flex;
  flex-flow: row wrap;
  gap: 0;
}

.scroller {
  overflow-y: scroll;
  border-radius: 8px;
}

.loader .deselect {
  float: right;
  font-weight: bold;
  margin: 0.3em 0em 0 0;
  padding: 1em;
  font-size: 11pt;
  cursor: pointer;
  color: #777;
}
.deselect:hover {
  color: #fff;
}

.rows ul {
  text-align: left; font-size: 10pt;
  padding: 0; margin: 0 4px 0 0;
  border-radius: 8px;
  height: 100%;
  overflow-y: scroll;
/*  background-color: #111;*/
}
.rows li a {
  margin: 1px 1px 2px 3px;
  padding: 2px 0 0 2px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 3px;
  text-decoration: none;
  color: white;
}
.rows li a:hover {
/*  background-color: #202020 !important;*/
  border: 1px solid #555;
}
.rows li .stack {
  display: flex;
  flex-direction: column;
}
.rows li .title {
  display: inline-block;
  padding: 0px 7px 3px 5px;
  cursor: pointer;
/*  background: #2c2c2c;*/
  color: #bbb;
  max-width: 200px;
  flex: 1 0 50%;
/*  border: 1px solid red;*/
}
.rows li .counts {
  text-align: right;
  flex: 1 0 0px;
  color: #444;
/*  padding-right: 4px;*/
/*  border: 1px solid green;*/
}
.rows li.selected .counts, .rows li a:hover .counts {
  color: #666;
}
.rows li a.spotify {
  visibility: hidden;
  text-decoration: none;
  background: transparent;
  color: #777;
}
.rows li.selected {
/*  border: 1px solid #222;*/
  background-color: #2a2a2a;
/*  #333;*/
}
.rows li.selected .title {
  color: #ddd;
}
.rows li a:hover .title {
  color: #fff;
}
.rows li a:hover a.spotify {
  visibility: visible;
}
.rows li.playing a {
  border: 1px solid #444;
  background-color: #666;
}
.rows li.playing .title {
  color: #000;
}
.rows li.loading { color: #ccc; background-color: #393939; }
.grid li.loading { display: none; }
.trackPreviews {
  flex: 1 0;
}
.trackPreviews a {
  float: left;
  font-size: 10pt;
  color: #222;
  text-decoration: none;
}
.rows li a:hover .trackPreviews a:hover {
 color: #fff; 
/* cursor: default;*/
}

.info {
  width: 190px;
  padding: 1.8em 1.5em;
  line-height: 1.5em;
  font-size: 11pt;
}
.info a {
  text-decoration: underline;
  cursor: pointer;
  color:  #aaa;
}
.info a:hover {
  color: #fff;
}

@keyframes loadingGlow {
  0% { background-color: DarkBlue; }
  50% { background-color: DodgerBlue; }
  100% { background-color: DarkBlue; }
}

.rows .loadingIndicator {
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  margin: 0 0.5em 0 0;
  border: 1px solid #555;
}

.rows .selected .loadingIndicator {
  background-color: #bbb;
  border: 1px solid #999;
}

.rows .loading .loadingIndicator, .rows .selected.loading .loadingIndicator {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingGlow;
}

.rows .loading img, .rows .selected.loading img {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: pendingGlow;
}

.rows li img {
  margin-right: 5px;
  cursor: pointer;
  border: 1px solid #aaa;
}
.rows li.selected img, .rows li a:hover img {
  border: 1px solid #ddd;
}
.nomatch {
  display: none !important;
}

#mapWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 100vh;
}
#mapAndPlayer {
  display: flex;
  flex-direction: column;
  flex: 1 1 80%;
}

.vertBar {
  display: flex;
  flex: 1 1;
/*  width: ;*/
  flex-direction: column;
  border-bottom: 1px solid #222;
  background-color: #1b1b1b;
  padding: 1px;
}
#mapWrapper .vertBar {
  width: 200px;
}
.vertBar h2 {
  text-align: center;
  color: #777;
  padding: 0 10px;
}
.vertBar .colorizer {
  flex: 1 1 75%;
  padding: 1em;
  text-align: center;
}

.queue {
  background-color: black;
  color: #bbb;
  padding-bottom: 8px;
}
.queue h4 {
  padding: 0;
  font-size: 10pt;
  margin: 0 0 4px 6px;
  font-weight: normal;
}

.queuedTracks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 8px 8px;
}

.queuedTracks img {
/*  opacity: 0.5;*/
  width: 18px;
  height: 18px;
  margin: 0;
}

.queuedTracks img.button {
  opacity: 0.7;
  cursor: pointer;
  padding: 0 2px;
}

.queuedTracks img.button:hover {
  opacity: 1.0;
}


input { width: 40px; }
input[type="range"] { width: 100px; }

#svgWrapper {
  flex: 2 1;
  overflow: scroll;
/*  max-height: 92vh;*/
  max-width: 1000px;
}

@keyframes pendingGlow {
  0% { opacity: 0.4; }
  50% { opacity: 0.7; }
  100% { opacity: 0.4; }
}

.pending {
/*  opacity: 0.5;*/
  animation-duration: 2s;
/*  animation-fill-mode: forwards;*/
  animation-iteration-count: infinite;
  animation-name: pendingGlow;
}

.rel {
  position: relative;
}

svg.map {
  touch-action: none;
  overflow: visible;
}

svg text { fill: white;  user-select: none; }
svg text.artistLabel {
  text-shadow: 1px 1px 2px #000;
  font-size: 1em;
}

.zoomControls { position: absolute; top: 0; left: 0; }
.zoomControls a { font-size: 1.1em; fill: #ccc; cursor: pointer; }
.zoomControls a:hover { fill: #fff; }

#hoverTrack {
  font-size: 11pt;
  position: absolute;
  user-select: none;
  visibility: hidden;
}
#svgWrapper #hoverTrack {
  visibility: visible;
}
#hoverTrack .head, #hoverTrack .features {
  background-color: rgba(50,50,50,0.7);
  padding: 5px 8px 10px 5px;
  margin-bottom: 8px;
}
#hoverTrack .features { display: inline-block; }
#hoverTrack p { margin: 0; padding: 3px 5px 0 5px; }
#hoverTrack .track { font-size: 1.1em; font-weight: bold; }
#hoverTrack .artist { font-size: 1.4em; }
#hoverTrack .releaseYear { font-size: 0.9em; }
#hoverTrack .features p {
  font-size: 0.8em;
  padding: 0 5px;
}

#hoverTrack .features span {
  display: inline-block;
  width: 2em;
  text-align: right;
}

.clickToActivate {
  display: none;
}

.rel:hover .clickToActivate {
  font-size: 1.7em;
  position: absolute;
  display: flex;
  /*align-items: center;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(50,50,50,0.6);
  vertical-align: middle;
}

.clickToActivate p {
  height:  150px;
  /*display: block;*/
  margin: auto auto;
}

svg a text {
  display:  none;
  z-index: 1;
  background-color: white;
}

svg .features tspan { font-size: 0.9em; }

svg a { cursor: pointer; }
svg a:hover circle { fill: white; }

video {
  height: 20px;
  width: 100%;
  line-height: 20px;
  display: none;
}

/*svg circle.recent {
  stroke: #aaa;
  stroke-width: 0.02;
}
*/
svg #dot0 circle.playing {
/*  stroke-width: 0.08;*/
  stroke: #888;
}

svg #dot1 circle.playing {
  animation: pulse 1.5s infinite;
/*  stroke-width: 0.08;*/
  stroke: #888;
}

@keyframes pulse {
  0% {
    fill: transparent;
    stroke-width: 0;
  }
  50% {
    fill: rgba(255,255,255,0.7);
    stroke-width: 0.04;
  }
  100% {
    fill: transparent;
    stroke-width: 0;
  }
}
